<template>
    <v-col cols="12" class="col-lg-3">
        <template v-if="quoteDetailLoading">
            <v-row v-for="(load, index) in [1, 2, 3]" :key="load+index">
                <v-col col="12" class="quote-skeleton">
                    <v-skeleton-loader :loading="true" type="image"></v-skeleton-loader>
                </v-col>
            </v-row>
        </template>
        <div class="quote-md-grid" v-if="!quoteDetailLoading">
            <v-card class="mb-5">
                <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                    <v-toolbar-title class="text-section-title">Quote Summary</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <h5 class="font-weight-bold justify-center black--text" align="center">
                        Total ${{ totalModel && totalModel.grand ? Number(totalModel.grand).toFixed(2) : 0.00 }}
                    </h5>
                    <v-row class="flex-column ma-0" justify="center" align="center">
                        <h5 class="pa-0 black--text font-weight-regular">
                            Products: ${{ productPrice ? Number(productPrice).toFixed(2) : 0.00 }}
                        </h5>
                    </v-row>
                    <v-divider class="my-3"/>
                    <v-row class="flex-column ma-0" justify="center" align="center">
                        <h5 class="pa-0 mb-1 black--text font-weight-regular">
                            PDR: ${{ totalModel && totalModel.pdr ? Number(totalModel.pdr).toFixed(2) : 0.00 }}
                        </h5>
                        <h5 class="pa-0 mb-1 black--text font-weight-regular">
                            Conventional: ${{ totalModel && totalModel.conventional ? Number(totalModel.conventional).toFixed(2) : 0.00 }}
                        </h5>
                        <h5 class="pa-0 mb-1 black--text font-weight-regular">
                            Replace / Paint: ${{ totalModel && totalModel.replace ? Number(totalModel.replace).toFixed(2) : 0.00 }}
                        </h5>
                        <h5 class="pa-0 black--text font-weight-regular">
                            Push to Paint: ${{ totalModel && totalModel.p2p ? Number(totalModel.p2p).toFixed(2) : 0.00 }}
                        </h5>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mb-5">
                <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                    <v-toolbar-title class="text-section-title">Scan Details</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row class="ma-0">
                        <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 black--text font-weight-regular">Registration:</h6>
                        </v-col>
                        <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
                            {{ scanModel && scanModel.licensePlate ? scanModel.licensePlate : '-' }}
                        </v-col>
                        <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 black--text font-weight-regular">Date/Time:</h6>
                        </v-col>
                        <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
                            {{ scanModel && scanModel.timestamp ? $helpers.getMomentDatas('DD-MM-YYYY hh:mm A', scanModel.timestamp) : '-' }}
                        </v-col>
                        <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 black--text font-weight-regular">Agent:</h6>
                        </v-col>
                        <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
                            {{ scanModel && scanModel.agent_name ? scanModel.agent_name : '-' }}
                        </v-col>
                        <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
                            <h6 class="pa-0 mb-1 black--text font-weight-regular">Client:</h6>
                        </v-col>
                        <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
                            {{ scanModel && scanModel.client_name ? scanModel.client_name : '-' }}
                        </v-col>
                    </v-row>
                    <v-card-actions class="justify-center">
                        <v-btn outlined color="primary" :to="`/scans/${scanId}`">
                            VIEW FULL SCAN DATA
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
            <v-card v-if="scanModel && scanModel.pdf_url">
                <v-toolbar color="primary" dark class="scan-title-bar" elevation="3">
                    <v-toolbar-title class="text-section-title">Vehicle Damage Report</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row class="ma-0 cursor-pointer" align="center" justify="center" @click="openPDF()">
                        <v-icon size="80" color="error darken-2">
                            mdi-file-pdf-box
                        </v-icon>
                        <div class="ml-2 text-h6 font-weight-medium text-break">
                            View PDF
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:['totalModel', 'productPrice'],
    computed: {
        ...mapGetters(['quoteDetails', 'quoteDetailLoading'])
    },
    data: (vm) => ({
        scanId: vm.$route.params.quoteScanId,
        scanModel: null
    }),
    watch: {
       quoteDetails(newVal) {
            if (newVal && newVal.scanInfo) this.scanModel = newVal.scanInfo;
            else this.scanModel = null;
       } 
    },
    created() {
        if (this.quoteDetails && this.quoteDetails.scanInfo) this.scanModel = this.quoteDetails.scanInfo;
        else this.scanModel = null;
    },
    methods: {
        openPDF() {
            window.open(this.scanModel.pdf_url, '_blank');
        },
    }
}
</script>

<style>

</style>